import React, { useState, useEffect } from "react";
import PercentCard from "./PercentCard";
import Popup from "./Popup";
import CardSelecionado from "./CardSelecionado";

const GridCards = () => {
  const [cards, setCards] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selecionado, setSelecionado] = useState();
  const [codigoValido, setCodigoValido] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    // Obter o código dos query parameters
    const queryParams = new URLSearchParams(window.location.search);
    const codigoURL = queryParams.get("code"); // Substitua 'code' pelo nome real do seu query parameter
    console.log(codigoURL);
    function gerarNumeroAleatorio(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    
    let registros = [
  {
    "nome": "Tigre",
    "percent": 91,
    "link": "",
    "maxima": 80,
    "padrao": 60,
    "minima": 40,
    "imgUrl": "https://popbrapronto.com/assets/rtp/pgsoft/POPBRA-PGSOFT2.webp"
  },
  {
    "nome": "Boi",
    "percent": 71,
    "link": "",
     "maxima": 80,
    "padrao": 60,
    "minima": 40,
    "imgUrl": "https://popbrapronto.com/assets/rtp/pgsoft/POPBRA-PGSOFT3.webp"
  },
  {
    "nome": "Coelho",
    "percent": 78,
    "link": "",
     "maxima": 80,
    "padrao": 60,
    "minima": 40,
    "imgUrl": "https://popbrapronto.com/assets/rtp/pgsoft/POPBRA-PGSOFT1.webp"
  },
  {
    "nome": "Rato",
    "percent": 82,
     "maxima": 80,
    "padrao": 60,
    "minima": 40,
    "link": "",
    "imgUrl": "https://popbrapronto.com/assets/rtp/pgsoft/POPBRA-PGSOFT4.webp"
  },
  {
    "nome": "Ganesha",
    "percent": 60,
     "maxima": 80,
    "padrao": 60,
    "minima": 40,
    "link": "",
    "imgUrl": "https://popbrapronto.com/assets/rtp/pgsoft/POPBRA-PGSOFT6.webp"
  },
  {
    "nome": "Double",
    "percent": 82,
     "maxima": 80,
    "padrao": 60,
    "minima": 40,
    "link": "",
    "imgUrl": "https://popbrapronto.com/assets/rtp/pgsoft/POPBRA-PGSOFT7.webp"
  },
  {
    "nome": "Dragon Tiger",
    "percent": 52,
     "maxima": 80,
    "padrao": 60,
    "minima": 40,
    "link": "",
    "imgUrl": "https://popbrapronto.com/assets/rtp/pgsoft/POPBRA-PGSOFT8.webp"
  },
  {
    "nome": "Prosperity Fortune Tree",
    "percent": 43,
     "maxima": 80,
    "padrao": 60,
    "minima": 40,
    "link": "",
    "imgUrl": "https://popbrapronto.com/assets/rtp/pgsoft/POPBRA-PGSOFT42.webp"
  }
]

registros = registros.map((registro) => {
  if (["Coelho", "Boi", "Tigre", "Rato"].includes(registro.nome)) {
    registro.percent = gerarNumeroAleatorio(70, 91);
  } else {
    registro.percent = gerarNumeroAleatorio(0, 91);
  }
  // registro.maxima = gerarNumeroAleatorio(20, 91);
  // registro.padrao = gerarNumeroAleatorio(20, 91);
  // registro.minima = gerarNumeroAleatorio(20, 91);
  return registro;
});
setCodigoValido(true);
setCards(registros)

    // fetch("https://www.xcontactcenter.com.br/teste/cdn/roger/data.json")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     // Verificar se o código na resposta corresponde ao código da URL
    //     console.log(data.code);
    //     if (data.code == codigoURL) {
    //       setCards(data.data);
    //       setCodigoValido(true);
    //     }else {
    //       setCards(data.data);
    //       setCodigoValido(true);
    //     }
    //   })
    //   .catch((error) => setError(true));
  }, []);

  const openPopup = (item) => {
    setSelecionado(item);
    setIsPopupOpen(true);
  };

  const closePopup = () => setIsPopupOpen(false);

  if (!error && !codigoValido) {
    return <div className="text-white">Código inválido ou não fornecido.</div>;
  }

  return (
    <div className="flex-1 h-screen pb-48 container mx-auto px-4 bg-black overflow-y-scroll md:overflow-hidden">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {cards.map((card, index) => (
          <div key={index} className="flex flex-col items-center">
          <PercentCard
            progress={card.percent}
            url={card.link}
            image={card.imgUrl}
          />
          <button
            className="mt-4 bg-yellow-500 text-black font-bold py-2 px-4 rounded hover:bg-yellow-600"
            onClick={() => openPopup(card)}
          >
            Jogue aqui
          </button>
        </div>
          
        ))}
      </div>
      <Popup isOpen={isPopupOpen} onClose={closePopup}>
        <CardSelecionado item={selecionado} />
      </Popup>
    </div>
  );
};

export default GridCards;
