import "./App.css";
import GridCards from "./GridCards";
import SiteLoader from "./Plataforma";
import TigerIcon from "./tiger-icon.png"; // Ajuste o caminho conforme necessário
import MoneyIcon from "./money-icon.webp"; // Ajuste o caminho conforme necessário
import HackerIcon from "./hacker.png"; // Ajuste o caminho conforme necessário
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Toast from "./Toast";

function App() {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const showToastWithMessage = (message) => {
    setToastMessage(message);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 5000); // O toast desaparece após 5 segundos
  };
  useEffect(() => {
    showToastWithMessage("Bem-vindo ao Script Slots! Clique em um jogo para começar.");
  
    // setInterval(() => {
    //   showToastWithMessage(`Um jogador acabou de sacar ${+ Math.floor(Math.random() * 1000) } reais!`);
    // }, 10000)
    // toast.info('Bem-vindo ao Script Slots! Clique em um jogo para começar.', {
    //   position: "top-right",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    // });
  }, [])
  return (
    <div className="bg-black w-full flex-col max-h-full h-screen overflow-hidden">
      <div className="flex flex-row items-center justify-center mb-4 px-24">
        {/* Ícone de tigre antes do texto */}
        <img src={TigerIcon} alt="Tiger Icon" className="h-36 w-44 mr-2" /> {/* Ajuste o tamanho conforme necessário */}
        <h2 className="text-4xl font-bold text-white"><span className="text-yellow-500">SCRIPT</span> SLOTS</h2>
        {/* Ícone de dinheiro depois do texto */}
        <img src={HackerIcon} alt="Money Icon" className="h-30 w-40" /> 
        
      </div>
      {/* <div className="flex flex-row items-center justify-center mb-4 px-4 sm:px-10 md:px-20 lg:px-40">
        <p className="text-red-400 text-sm sm:text-base md:text-lg font-bold">
          O que é RTP (Return to Player)? Quando você joga em Slots, o RTP é a
          porcentagem de apostas que a máquina está programada para pagar aos
          jogadores. Uma porcentagem maior significa que você tem mais chances
          de ganhar!
        </p>
      </div> */}
      {/* <SiteLoader /> */}
      <GridCards />
      {showToast && <Toast message={toastMessage} className="toast" />}
    </div>
  );
}

export default App;
