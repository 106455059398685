import React from "react";
import ProgressBar from "./ProgressBar";

export default function PercentCard({ progress, url, image }) {
  return (
    <div className="flex items-center justify-center">
      <div className="flex flex-col items-center border rounded-lg h-60 w-80 p-1 shadow-md">
        <img className=" w-full h-30" src={image} />
        <ProgressBar progress={progress} />
      </div>
    </div>
  );
}
