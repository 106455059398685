import React from "react";

const Popup = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
      onClick={onClose}
    >
      <div
        className="relative top-20 mx-auto p-5 border w-full shadow-lg rounded-md bg-black"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
        <button
          className="absolute top-0 right-0 mt-4 mr-4 text-white"
          onClick={onClose}
        >
          X
        </button>
      </div>
    </div>
  );
};

export default Popup;
