import React, { useState } from 'react';
import Swal from 'sweetalert2'; // Importa SweetAlert2

function SiteLoader() {
  const [url, setUrl] = useState('https://www.lobisomem777.com/?ic=29756870'); // Substitua 'https://example.com' pela URL inicial desejada
  const [strategy, setStrategy] = useState('');

  const generateStrategy = () => {
    console.log('Gerando estratégia...');
    const randomNumber = Math.floor(Math.random() * 5) + 6; // Gera um número de 6 a 10
    const strategyType = Math.floor(Math.random() * 3); // Gera um número entre 0 e 2 para escolher o tipo de estratégia
    let selectedStrategy = '';
  
    if (strategyType === 0) { // Turbo
      selectedStrategy = `${randomNumber}x Turbo`;
    } else if (strategyType === 1) { // Normal
      selectedStrategy = `${randomNumber}x Normal`;
    } else { // Intercalado
      selectedStrategy = `${randomNumber}x Intercalado (Turbo, Normal)`;
    }
    
    setStrategy(selectedStrategy); // Atualiza o estado com a nova estratégia
    Swal.fire({
        title: 'Sucesso!',
        text: 'Estratégia gerada com sucesso!',
        icon: 'success',
        confirmButtonText: 'Ok'
      });
  };

  return (
    <div className='h-screen w-full text-white flex flex-col justify-center items-center'>
      <div className="flex flex-col items-center">
        <button
          onClick={()=> generateStrategy()}
          className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
          >
          Gerar Estratégia
        </button>
        {strategy && <p className="mt-4">Estratégia Gerada: {strategy}</p>}
      </div>
      <iframe className='mt-1 pb-24 h-screen overflow-y-scroll iframe-zoom-out' src={url} title="Site Loader" style={{border: 'none', width: '100%', height: '100%'}}
      ></iframe>
    </div>
  );
}

export default SiteLoader;