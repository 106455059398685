import React from "react";
import ProgressBar from "./ProgressBar";
import SiteLoader from "./Plataforma";

export default function CardSelecionado({ item }) {
  const [isJogar, setIsJogar] = React.useState(false);
  const jogar = () => {
    console.log("Jogar");
    setIsJogar(true)

  }
  return (
    <div className="flex items-center justify-center">
     
        {isJogar ? <SiteLoader /> : (
          <>
           <div className="flex flex-col items-center  rounded-lg p-1">
        <img className=" w-full h-60" src={item.imgUrl} alt="teste" />
        <div className="flex flex-col space-y-1 w-full">
          <h2 className="text-white font-bold">Bet padrao</h2>
          <ProgressBar progress={item.padrao} />
        </div>
        <div className="flex flex-col space-y-1 w-full">
          <h2 className="text-white font-bold">Bet maxima</h2>
          <ProgressBar progress={item.maxima} />
        </div>
        <div className="flex flex-col space-y-1 w-full">
          <h2 className="text-white font-bold">Bet minima</h2>
          <ProgressBar progress={item.minima} />
        </div>
        <button className="mt-2 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded" onClick={jogar}>Jogar</button>
        </div>
          </>
        )}

     
    </div>
  );
}
