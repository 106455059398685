import React from "react";

const ProgressBar = ({ progress }) => {
  // Garantir que o progresso esteja entre 0 e 100
  const validProgress = Math.min(100, Math.max(0, progress));

  // Determinar a cor da barra de progresso
  const progressBarColor =
    validProgress <= 40
      ? "bg-red-600" // Vermelho para progresso de 0 a 40
      : validProgress <= 75
      ? "bg-yellow-600" // Amarelo para progresso de 41 a 75
      : "bg-green-600"; // Verde para progresso de 76 a 100

  return (
    <div className="mt-1 w-full bg-gray-200 rounded-full dark:bg-gray-700">
      <div
        className={`${progressBarColor} text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full`}
        style={{ width: `${validProgress}%` }}
      >
        {validProgress}%
      </div>
    </div>
  );
};

export default ProgressBar;
